<template>
  <AppAutocomplete
    :model-value="
      (selectedLocation?.type === 'geolocation'
        ? selectedLocation?.text
        : selectedLocation?.value) || ''
    "
    :options="s => getLocationOptions(s, searchNew)"
    :clearable="Boolean(selectedLocation?.value)"
    :readonly="
      selectedLocation?.type && selectedLocation.type === 'geolocation'
    "
    v-bind="attrs"
    :custom-button-emit="hasLocation"
    :name="fieldName"
    @item-selected="($event) => {
      selectedLocation = $event as Item
    }"
    @close="closeOptions"
    @click-button="clickCustomButton"
    @update:search="updateSearch"
  >
    <template #option="item">
      <div
        class="flex gap-2 items-center"
        :class="{
          'text-blue-dark-desaturated font-bold': item.type === 'geolocation'
        }"
      >
        <Icon
          v-if="item.type === 'geolocation'"
          class="min-w-8 w-8 h-8 -ml-1"
          :path="mdiMapMarkerOutline"
        />
        <Icon
          v-else-if="item.recent"
          class="min-w-8 w-8 h-6 -ml-1"
          :path="mdiClock"
        />
        <Icon v-else class="min-w-8 w-8 h-6 -ml-1" :path="mdiMagnify" />
        <div class="flex flex-col">
          <span>
            {{ item.text }}
          </span>
          <span
            v-if="item.type !== 'geolocation'"
            class="text-sm text-neutral-light"
          >
            {{ $t(`locationTypes.${item.type}`, item.type) }}
          </span>
        </div>
      </div>
    </template>
    <template #button>
      <slot name="button"> </slot>
    </template>
  </AppAutocomplete>
</template>

<script lang="ts">
import { mdiClock, mdiMagnify, mdiMapMarkerOutline } from '@mdi/js';
import type { User } from '@nhost/vue';

// import { useMainStore } from '@/store';
import { useSearchStore } from '@/store/search';

const AppAutocomplete = defineAsyncComponent(
  () => import('@/async-components/App/Autocomplete.vue')
);

export default {
  inheritAttrs: false
};
</script>

<script setup lang="ts">
interface Item {
  text: string;
  value: string;
  type: string;
}

const props = defineProps<{
  modelValue?: Item;
  searchNew?: boolean;
  fieldName: string;
  useLocationFromStore?: boolean;
}>();

const attrs = useAttrs();

const { t } = useI18n();

const {
  public: { apiUrl }
} = useRuntimeConfig();

const { get } = useHttpMethods();

type Emits = {
  (e: 'update:search', value: string, oldValue?: string | undefined): void;
  (e: 'update:model-value', value: Item, oldValue?: Item | undefined): void;
};

const emit = defineEmits<Emits>();

// const store = useMainStore();

const searchStore = useSearchStore();

let isMounted = false;

const selectedLocation = ref<Item | null>(null);

const updateSearch = (value: string = '') => {
  if (!isMounted) {
    return;
  }

  const newQuery = selectedLocation.value
    ? { ...selectedLocation.value }
    : { text: value, value, type: 'void' };

  if (
    searchStore.query?.value?.toLowerCase() !== newQuery.value?.toLowerCase() ||
    searchStore.query?.type !== newQuery.type ||
    Object.keys(newQuery).length >=
      Object.keys(searchStore.query || {}).length ||
    !newQuery.value
  ) {
    searchStore.setQuery(newQuery);
  }

  emit('update:search', value);
};

const enableGeolocation = ref(false);

const hasLocation = computed(() => {
  return true; // !store.userCountry || store.userCountry === 'US';
});

function clickCustomButton(isOpen: boolean) {
  if (hasLocation.value) {
    if (isOpen) {
      enableGeolocation.value = !enableGeolocation.value;
    } else {
      enableGeolocation.value = false;
    }
  }
}

function closeOptions() {
  enableGeolocation.value = false;
}

const user = inject('user', null) as Ref<User | null> | null;

async function getLocationOptions(search?: string, searchNew = false) {
  const useGeolocation = hasLocation.value && enableGeolocation.value;

  const currentLocationItem = useGeolocation
    ? {
        text: t('home.currentLocation'),
        value: 'current-location',
        type: 'geolocation'
      }
    : null;

  if (!search) {
    return [
      currentLocationItem,
      ...searchStore.savedQueries.map(q => ({ ...q, recent: true }))
    ].filter(Boolean);
  }

  const queryParams = {
    q: search || '*',
    query_by: 'label',
    filter_by: searchNew
      ? 'meta.status:!=on-the-market'
      : '(meta.status:[on-the-market,NA] || meta.status:!=* )',
    exclude_fields: 'meta.geojson,created_at,type_order',
    highlight_fields: 'x',
    'x-typesense-user-id': user?.value?.id || ''
  };

  const url =
    apiUrl +
    '/search/insta-search/?' +
    Object.entries(queryParams)
      .map(vs => new URLSearchParams({ [vs[0]]: vs[1] }))
      .join('&');

  const response = await get<{
    data: {
      hits: {
        document: {
          id: string;
          label: string;
          type_order: number;
          meta: {
            type: string;
            id: string;
            city: string;
            county: string;
            postal_code: string;
            full: string;
            transaction_type?: 'RES' | 'RNT';
            transacion_type: 'RES' | 'RNT';
            subType: string;
            status: string;
            project_name: string;
          };
        };
      }[];
    };
  }>(url);

  const items = response.data.hits
    .map(hit => ({
      ...(hit.document.meta || {}),
      ...hit.document,
      transaction_type:
        hit.document.meta.transaction_type || hit.document.meta.transacion_type,
      itemId: hit.document.meta.id,
      text: hit.document.label,
      value: hit.document.label
    }))
    .filter(Boolean)
    .filter(
      (item, index, items) =>
        index === items.findIndex(t => t.value === item.value)
    );

  return items.length
    ? [currentLocationItem, ...items].filter(Boolean)
    : [
        currentLocationItem,
        ...searchStore.savedQueries.map(q => ({ ...q, recent: true }))
      ].filter(Boolean);
}

const getCurrentLocation = useCurrentLocation();

watch(
  selectedLocation,
  () => {
    (async () => {
      updateSearch();
      if (
        selectedLocation.value?.type === 'geolocation' &&
        selectedLocation.value?.value === 'current-location'
      ) {
        const currentLocation = await getCurrentLocation();
        if (currentLocation) {
          selectedLocation.value = {
            ...selectedLocation.value,
            value: currentLocation
          };
          return; // do not emit
        }
      }
      await nextTick();
      emit('update:model-value', selectedLocation.value);
      emit('update:search', selectedLocation.value?.value || '');
    })();
  },
  { immediate: true }
);

onMounted(() => {
  if (props.useLocationFromStore) {
    if (!props.modelValue && searchStore.query?.value) {
      console.log('[DEBUG] Loading query from store', searchStore.query);
    }

    selectedLocation.value =
      props.modelValue ||
      (searchStore.query &&
      searchStore.query?.type !== 'void' &&
      searchStore.query?.value
        ? searchStore.query
        : null);
  } else {
    selectedLocation.value = props.modelValue || null;
  }

  isMounted = true;
});
</script>

<style scoped lang="scss">
:deep(input) {
  padding-right: 48px !important;
  &:placeholder-shown {
    text-overflow: ellipsis;
  }
  &::placeholder {
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    overflow: hidden;
  }
}
</style>

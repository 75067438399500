export default function () {
  let _geolocation: GeolocationPosition | null = null;

  const getGeolocation = () => {
    return new Promise(resolve => {
      if (_geolocation) {
        resolve(_geolocation);
        return;
      }

      if (typeof navigator !== 'undefined' && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            _geolocation = position;
            resolve(position);
          },
          () => {
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    });
  };

  const getCurrentLocation = async () => {
    const position = await getGeolocation();

    if (!position) {
      return null;
    }

    return `${position.coords.latitude},${position.coords.longitude}`;
  };

  return getCurrentLocation;
}
